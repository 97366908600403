const styles = {
    appbar: {
        display: "flex", flexDirection: "row-reverse", zIndex: 2000
    },
    main: {
        display: "flex", alignContent: "center", justifyContent: "center"
    },
    container: {
        paddingTop: 64, paddingBottom: 32
    },
    headline: {
        marginBottom: 16, marginTop: 48
    },
    subheadline: {
        textAlign: "center", marginBottom: 16
    },
    subcontainer: {
        display: "flex", width: "100%", justifyContent: "center", marginBottom: 32
    },
    output: {
        width: "80vw"
    },
    configurationContainer: {
        height: '85%', paddingBottom: 128
    },
    sectionDialogContainer: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', width: 400
    },
    keywordsDialogContainer: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', width: 800
    },
    sectionsDataGridToolbar: {
        padding: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'
    },
    sectionsDataGridToolbarContainer: {
        display: 'flex', flexDirection: 'row', alignItems: 'center'
    },
    fullWidth: {
        width: '100%'
    },
    fullDimensions: {
        width: '100%', height: '100%'
    },
    goToButtonText: {
        cursor: 'pointer',
    },
    darkModeSwitchContainer: {
        padding: 16, display: 'flex', flexDirection: 'row', zIndex: 1400, alignItems: 'center',
        position: 'relative', bottom: 0, justifyContent: 'flex-end'
    },
    table: {
      minWidth: 650,
    },
    chatSection: {
      width: '100%',
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
      height: '70vh',
      overflowY: 'auto'
    }
}

export default styles

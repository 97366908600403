import React from 'react'
import {LinearProgress, Typography} from "@mui/material";

const FormLoadingBar = ({lastMessage}) => <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: 16 }}>
    <div style={{ width: '100%', height: 32, display: 'flex', alignItems: 'center' }}>
        <LinearProgress style={{height: 4, width: '100%'}} />
    </div>
    { lastMessage && <Typography>{lastMessage}</Typography> }
</div>

export default FormLoadingBar
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import { handleResponse } from './../../utils/tools';

const styles = {
  row: {
    marginTop: 15,
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}

class StorageView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      backupName: '',
      changes: false,
      isNew: false,
    }
  }

  componentDidMount() {
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleSubmit = () => {
    let name = this.state.backupName;
    this.setState({changes: false});
    global.fetch(`/db/backup/${name}?token=${this.props.token}`, {
      credentials: 'include',
      method: 'GET',
      headers: new Headers({'Content-Type': 'application/json',}),
    }).then(response => handleResponse(response))
    .then(_ => this.handleClose())
    .catch(error => {
      console.error(error);
      this.props.setErrorMessage(error.message);
      this.handleClose();
    });
  }

  isAplhanumeric = (str) => {
    let matchEx = /^\w+$/g;
    return !str || str.match(matchEx);
  }

  canSubmit = () => {
    let {backupName, changes} = this.state;

    let ok = this.isAplhanumeric(backupName);
    return changes && ok;
  }

  handleFieldChange = event => {
    this.setState({backupName: event.target.value, changes: true});
  }

  render() {
    let {backupName} = this.state;
    let {classes} = this.props;
    let submitLabel = "Create Backup";
    let label = "Backup Name";
    let errorCT = false;
    if (!this.isAplhanumeric(backupName)) {
      errorCT = true;
      label = "Alphanumeric 16 characters field!"
    }
    let title = "Backup Name";

    return (
      <Dialog
          open={true}
          aria-labelledby="conversation-editor-dialog-title"
        >
        <DialogTitle id="conversation-editor-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <TextField key={'cv_title'} value={backupName} multiline={false}
            fullWidth={true} label={label} maxLength={16}
            onChange={this.handleFieldChange}
            className={classes.row}
            error={errorCT}
            autoFocus
          />
          <Divider />
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={this.handleClose}>Cancel</Button>
          <Button
            color='primary'
            disabled={!this.canSubmit()}
            onClick={this.handleSubmit}>{submitLabel}</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

StorageView.propTypes = {
  classes: PropTypes.object.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
}

export default withStyles(StorageView, styles);

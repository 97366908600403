import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import FilterListIcon from '@mui/icons-material/FilterList';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/system/Box';
import { alpha } from '@mui/material/styles';

import MessageAlert from "./../../components/MessageAlert";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: 'email', numeric: false, disablePadding: true, label: 'ID' },
	{ id: 'authorized', numeric: false, disablePadding: true, label: 'Authorized Access' },
	{ id: 'approver', numeric: false, disablePadding: true, label: 'Admin' },
];

function UserTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all users' }}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

UserTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};


const UserTableToolbar = (props) => {
	const { numSelected, openFilterHandle, approveHandle, denyHandle,
		adminHandle, deleteUserHandle, denyAdminHandle } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
				}),
			}}
		>
			{numSelected > 0 ? (
				<Typography
					sx={{
						flex: '1 1 100%',
					}}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} selected
				</Typography>
			) : (
				<Typography
					sx={{
						flex: '1 1 100%',
					}}
					variant="h6"
					id="tableTitle"
					component="div">
					User Access Management
				</Typography>
			)}

			{numSelected > 0 ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Tooltip title="Approve">
						<IconButton aria-label="approve access" onClick={approveHandle}>
							<ThumbUpIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Deny">
						<IconButton aria-label="deny access" onClick={denyHandle}>
							<ThumbDownIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Admin">
						<IconButton aria-label="approve admin" onClick={adminHandle}>
							<SupervisorAccountIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Deny Admin">
						<IconButton aria-label="remove admin" onClick={denyAdminHandle}>
							<PersonRemoveIcon />
						</IconButton>
					</Tooltip>
					{numSelected === 1 && (
						<Tooltip title="Delete User">
							<IconButton aria-label="delete user" onClick={deleteUserHandle}>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					)}

				</Box>
			) : (
				<Tooltip title="Filter list">
					<IconButton aria-label="filter list" onClick={openFilterHandle}>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

UserTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
	openFilterHandle: PropTypes.func.isRequired,
	approveHandle: PropTypes.func.isRequired,
	denyHandle: PropTypes.func.isRequired,
	adminHandle: PropTypes.func.isRequired,
	denyAdminHandle: PropTypes.func.isRequired,
	deleteUserHandle: PropTypes.func.isRequired,
};

export default function UserTable(props) {
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('email');
	const [rows, setRows] = React.useState([]);
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [filter, setFilter] = React.useState({ open: false, filterOn: "" });
	const [approveAction, setApproveAction] = React.useState(false);
	const [denyAction, setDenyAction] = React.useState(false);
	const [adminAction, setAdminAction] = React.useState(false);
	const [denyAdminAction, setDenyAdminAction] = React.useState(false);
	const [deleteAction, setDeleteAction] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [infoMessage, setInfoMessage] = useState('')

	const theme = useTheme();

	const useComponentWillMount = callback => {
		const [mounted, setMounted] = useState(false)
		if (!mounted) callback()

		useEffect(() => {
			setMounted(true)
		}, [])
	};

	const loadUserList = () => fetch(`/user/all?token=${props.token}`, {
		credentials: 'include',
		method: 'GET',
		headers: new Headers({ 'Content-Type': 'application/json', }),
	}).then(response => response.json())
		.then(content => {
			setRows(content.filter(u => !filter || u.email.toLowerCase().includes(filter.filterOn.toLocaleLowerCase())));
			setSelected([]);
		})
		.catch((error) => {
			setErrorMessage(error.message);
		});


	useComponentWillMount(() => {
		loadUserList();
	})

	const handleRequestSort = (_, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

  useEffect(() => {
      if (errorMessage !== '') setTimeout(() => setErrorMessage(''), 3000)
      if (infoMessage !== '') setTimeout(() => setInfoMessage(''), 3000)
  }, [errorMessage, infoMessage])

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.email);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (_, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (_, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = (event) => {
		setDense(event.target.checked);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	const handleFilterClose = () => {
		setFilter({
			open: false,
			filterOn: filter.filterOn,
		});
	}

	const handleFilterOpen = () => {
		setFilter({
			open: true,
			filterOn: filter.filterOn,
		});
	}

	const handleFilterChange = event => {
		setFilter({
			open: filter.open,
			filterOn: event.target.value,
		});
	}

	const handleFilterDo = () => {
		handleFilterClose();
		loadUserList();
	}

	const handleApproveAction = () => {
		setApproveAction(true);
	}

	const handleApproveActionClose = () => {
		setApproveAction(false);
	}

	const handleApproveActionDo = () => {
		handleApproveActionClose();
		let users = selected.join(',');

		global.fetch(`/user/approve?token=${props.token}`, {
			credentials: 'include',
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/text', }),
			body: users,
		})
			.then(_ => {
				loadUserList();
			})
			.catch(error => {
				setErrorMessage(error.message);
			});
	}

	const handleDenyAction = () => {
		setDenyAction(true);
	}

	const handleDenyActionClose = () => {
		setDenyAction(false);
	}

	const handleDenyActionDo = () => {
		handleDenyActionClose();
		let users = selected.join(',');

		global.fetch(`/user/deny?token=${props.token}`, {
			credentials: 'include',
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/text', }),
			body: users,
		})
			.then(_ => {
				loadUserList();
			})
			.catch(error => {
				setErrorMessage(error.message);
			});
	}

	const handleAdminAction = () => {
		setAdminAction(true);
	}


	const handleAdminActionClose = () => {
		setAdminAction(false);
	}

	const handleAdminActionDo = () => {
		handleAdminActionClose();
		let users = selected.join(',');

		global.fetch(`/user/admin?token=${props.token}`, {
			credentials: 'include',
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/text', }),
			body: users,
		})
			.then(_ => {
				loadUserList();
			})
			.catch(error => {
				setErrorMessage(error.message);
			});
	}

	const handleDenyAdminAction = () => {
		setDenyAdminAction(true);
	}

	const handleDenyAdminActionClose = () => {
		setDenyAdminAction(false);
	}

	const handleDenyAdminActionDo = () => {
		handleDenyAdminActionClose();
		let users = selected.join(',');

		global.fetch(`/user/deny/admin?token=${props.token}`, {
			credentials: 'include',
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/text', }),
			body: users,
		})
			.then(_ => {
				loadUserList();
			})
			.catch(error => {
				setErrorMessage(error.message);
			});
	}

	const handleDeleteAction = () => {
		setDeleteAction(true);
	}

	const handleDeleteActionClose = () => {
		setDeleteAction(false);
	}

	const handleDeleteActionDo = () => {
		handleDeleteActionClose();
		let users = selected.join(',');

		global.fetch(`/user/delete?token=${props.token}`, {
			credentials: 'include',
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/text', }),
			body: users,
		})
			.then(_ => {
				loadUserList();
			})
			.catch(error => {
				setErrorMessage(error.message);
			});
	}

	return (
		<Box
			sx={{
				width: '100%',
        height: '100%',
        paddingTop: theme.spacing(7)
			}}>
			<Paper
				sx={{
					width: '100%',
				}}
			>
				<UserTableToolbar numSelected={selected.length} openFilterHandle={handleFilterOpen}
					approveHandle={handleApproveAction} denyHandle={handleDenyAction}
					adminHandle={handleAdminAction} denyAdminHandle={handleDenyAdminAction}
					deleteUserHandle={handleDeleteAction} />
				<TableContainer>
					<Table
						sx={{
							minWidth: 750,
						}}
						aria-labelledby="tableTitle"
						size={dense ? 'small' : 'medium'}
						aria-label="enhanced table"
					>
						<UserTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody>
							{stableSort(rows, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const isItemSelected = isSelected(row.email);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, row.email)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={"user-" + index}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													checked={isItemSelected}
													inputProps={{ 'aria-labelledby': labelId }}
												/>
											</TableCell>
											<TableCell component="th" id={labelId} scope="row" padding="none">
												{row.email}
											</TableCell>
											<TableCell align="left">{row.authorized ? 'Y' : 'N'}</TableCell>
											<TableCell align="left">{row.approver ? 'Y' : 'N'}</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
      <FormControlLabel
        sx={{
          height: theme.spacing(10)
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
			<Dialog
				open={filter.open}
				onClose={handleFilterClose}
				aria-labelledby="filter-dialog-title"
				aria-describedby="filter-dialog-description"
			>
				<DialogTitle id="filter-dialog-title">User Filter</DialogTitle>
				<DialogContent>
					<DialogContentText id="filter-dialog-description">
						User ID to filter on, empty for all...
					</DialogContentText>
					<TextField key={'name_filter'} value={filter.filterOn} multiline={false}
						fullWidth={true} label="ID Filter" autoFocus
						onChange={handleFilterChange} maxLength={128}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						color='primary'
						onClick={handleFilterClose}>
						Cancel
					</Button>
					<Button
						color='primary'
						onClick={handleFilterDo}>
						Filter
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={approveAction}
				onClose={handleApproveActionClose}
				aria-labelledby="approve-dialog-title"
				aria-describedby="approve-dialog-description"
			>
				<DialogTitle id="approve-dialog-title">User Access</DialogTitle>
				<DialogContent>
					<DialogContentText id="approve-dialog-description">
						Approve access for {selected.length} selected users?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color='primary'
						onClick={handleApproveActionClose}>
						Cancel
					</Button>
					<Button
						color='primary'
						onClick={handleApproveActionDo}>
						Approve
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={denyAction}
				onClose={handleDenyActionClose}
				aria-labelledby="deny-dialog-title"
				aria-describedby="deny-dialog-description"
			>
				<DialogTitle id="deny-dialog-title">Deny User Access</DialogTitle>
				<DialogContent>
					<DialogContentText id="deny-dialog-description">
						Deny access for {selected.length} selected users?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color='primary'
						onClick={handleDenyActionClose}>
						Cancel
					</Button>
					<Button
						color='primary'
						onClick={handleDenyActionDo}>
						Deny Access
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={adminAction}
				onClose={handleAdminActionClose}
				aria-labelledby="admin-dialog-title"
				aria-describedby="admin-dialog-description"
			>
				<DialogTitle id="admin-dialog-title">Admin User Access</DialogTitle>
				<DialogContent>
					<DialogContentText id="admin-dialog-description">
						You are about to grant admin access for {selected.length} selected users. Are you sure?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color='primary'
						onClick={handleAdminActionClose}>
						Cancel
					</Button>
					<Button
						color='primary'
						onClick={handleAdminActionDo}>
						Make Admin
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={denyAdminAction}
				onClose={handleDenyAdminActionClose}
				aria-labelledby="deny-admin-dialog-title"
				aria-describedby="deny-admin-dialog-description"
			>
				<DialogTitle id="deny-admin-dialog-title">Remove Admin User Access</DialogTitle>
				<DialogContent>
					<DialogContentText id="deny-admin-dialog-description">
						You are about to remove admin access for {selected.length} selected users. Are you sure?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color='primary'
						onClick={handleDenyAdminActionClose}>
						Cancel
					</Button>
					<Button
						color='primary'
						onClick={handleDenyAdminActionDo}>
						Remove Admin
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={deleteAction}
				onClose={handleDeleteActionClose}
				aria-labelledby="delete-dialog-title"
				aria-describedby="delete-dialog-description"
			>
				<DialogTitle id="delete-dialog-title">Remove User</DialogTitle>
				<DialogContent>
					<DialogContentText id="delete-dialog-description">
						You are about to remove {selected.length} selected users. Are you sure?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color='primary'
						onClick={handleDeleteActionClose}>
						Cancel
					</Button>
					<Button
						color='primary'
						onClick={handleDeleteActionDo}>
						Remove User
					</Button>
				</DialogActions>
			</Dialog>
      <MessageAlert message={errorMessage} setMessage={setErrorMessage} />
      <MessageAlert message={infoMessage} setMessage={setInfoMessage} severity={'info'} />
		</Box>
	);
}

UserTable.propTypes = {
	token: PropTypes.string.isRequired,
};

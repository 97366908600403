import React from 'react'
import {Box, Button, Toolbar, Typography} from "@mui/material";
import styles from "../styles";
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ApplicationLogo from "../components/ApplicationLogo";
import AppBar from "@mui/material/AppBar";

const NoPage = () => {
    return <main style={styles.main}>
        <AppBar position="fixed" style={styles.appbar}>
            <Toolbar style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h6" noWrap>
                        Asclepius.ai
                    </Typography>
                    <ApplicationLogo/>
                </div>
            </Toolbar>
        </AppBar>
        <Toolbar />
        <Box style={styles.container}>
            <Typography variant={'h3'} style={{ paddingTop: 32 }}>
                404
            </Typography>
            <Typography variant={'h6'} style={{ marginBottom: 32 }}>
                Ops... seems like we don't have what you're looking for
            </Typography>
            <Button href={'/'} variant={'contained'}>Go home</Button>
            <Box style={{ padding: 64, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <AnnouncementIcon style={{ width: 128, height: 128 }} />
            </Box>
        </Box>
    </main>
}

export default NoPage
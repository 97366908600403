import {Alert, Slide} from "@mui/material";
import React from "react";


const MessageAlert = ({ message, setMessage, severity }) => {
    return <Slide direction={'down'} in={message !== ''}>
        <Alert severity={severity || 'error'}
               style={{position: 'absolute', top: 72, right: 8, zIndex: 99999}}
               sx={{boxShadow: 0}}
               onCLose={() => setMessage('')}
        >
            {message}
        </Alert>
    </Slide>
}

export default MessageAlert

import './App.css'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NoPage from "./pages/NoPage";
import LandingPage from "./pages/LandingPage";
import RequestAccess from "./pages/RequestAccess";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import React from "react";
import usePersistState from "./utils/usePersistState";
import {IS_DARK_MODE} from "./settings";

const App = () => {
    const [isDarkMode, setIsDarkMode] = usePersistState(IS_DARK_MODE, false)
    const params = new URLSearchParams(document.location.search)

    const darkTheme = createTheme({
        palette: {
            mode: isDarkMode ? 'dark' : 'light'
        }
    });

    return <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <BrowserRouter>
            <Routes>
                <Route index element={<LandingPage token={params.get('token')} isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />} />
                <Route path={'login'} element={<RequestAccess />} />
                <Route path={'*'} element={<NoPage />} />
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
}

export default App;
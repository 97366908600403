import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import RestoreIcon from '@mui/icons-material/Restore';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';

function getSorting(order) {
  return order === 'desc' ? (a, b) => compare(b, a) : (a, b) => compare(a, b);
}

function compare(a,b) {
  if (isNaN(a) || isNaN(b)) {
    if (typeof a === 'undefined' || a === null) {
      a = '';
    }
    if (typeof b === 'undefined' || b === null) {
      b = '';
    }
    return a.localeCompare(b);
  } else {
    return a - b;
  }
}

const rows = [
  { id: 'backupName', numeric: false, align: 'left', disablePadding: true, label: 'Backup Name' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 &&  numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                align={row.align}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.light,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, confirmDelete,
    addNew, openFilter, downloadBackup, restoreBackup,
    openUpload } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Storage Backup List
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <div className={classes.line}>
            {numSelected === 1 ? (
              <div className={classes.line}>
                <Tooltip title="Download">
                  <IconButton aria-label="Download" onClick={downloadBackup}>
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Restore">
                  <IconButton aria-label="Restore" onClick={restoreBackup}>
                    <RestoreIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div/>
            )}
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" onClick={confirmDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div className={classes.line}>
            <Tooltip title="New backup">
              <IconButton aria-label="New backup" onClick={addNew}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Upload">
              <IconButton aria-label="Upload" onClick={openUpload}>
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list" onClick={openFilter}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  addNew: PropTypes.func.isRequired,
  openFilter: PropTypes.func.isRequired,
  downloadBackup: PropTypes.func.isRequired,
  restoreBackup: PropTypes.func.isRequired,
  openUpload: PropTypes.func.isRequired,
};

EnhancedTableToolbar = withStyles(EnhancedTableToolbar, toolbarStyles);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    minWidth: 500,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class StorageTable extends React.Component {
  state = {
    order: 'desc',
    orderBy: '',
    selected: [],
    page: 0,
    rowsPerPage: 10,
    openConfirmRestore: false,
    openUpload: false,
    selectedFiles: undefined,
    currentFile: undefined,
    message: "",
    isError: false,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({ selected: this.props.backupList.map(n => n) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleConfirmDelete = event => {
    const { selected } = this.state;
    this.props.confirmDelete(selected, this.clearSelected);
  };

  handleAddNewConversation = () => {
    this.props.createBackup()
  }

  downloadBackup = () => {
    let backupName = this.state.selected[0];
    this.clearSelected();
    this.props.downloadBackup(backupName);
  }

  handleUploadClose = () => {
    this.setState({openUpload: false, })
  }

  selectFile = (event) => {
    this.setState({
      selectedFiles: event.target.files,
    });
  }

  handleUpload = () => {
    let currentFile = this.state.selectedFiles[0];
    this.setState({
      currentFile: currentFile,
    });

    this.props.upload(currentFile)
      .then(response => {
        this.setState({
          message: response.data,
          isError: false,
          openUpload: false,
        });
        this.props.loadStorageList();
      }).catch((error) => {
  console.log(error)
        this.setState({
          message: "Could not upload the file!",
          currentFile: undefined,
          isError: true
        });
      });
  }

  openUpload = () => {
    this.setState({openUpload: true, })
  }

  handleConfirmRestoreClose = () => {
    this.setState({openConfirmRestore: false, })
  }

  handleConfirmRestore = () => {
    let backupName = this.state.selected[0];
    this.setState({openConfirmRestore: false, selected: []})
    this.props.restoreBackup(backupName);
  }

  restoreBackup = () => {
    this.setState({openConfirmRestore: true});
  }

  clearSelected = () => this.setState({selected: []});

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, openFilter } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const data = this.props.backupList;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const {
      selectedFiles,
      message,
      isError
    } = this.state;


    return (
      <Box>
        <Paper className={classes.root}>
          <EnhancedTableToolbar numSelected={selected.length}
            confirmDelete={this.handleConfirmDelete}
            addNew={this.handleAddNewConversation}
            openFilter={openFilter}
            downloadBackup={this.downloadBackup}
            restoreBackup={this.restoreBackup}
            openUpload={this.openUpload}
            />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {data
                  .sort(getSorting(order))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(backupName => {
                    const isSelected = this.isSelected(backupName);
                    return (
                      <TableRow
                        hover
                        onClick={event => this.handleClick(event, backupName)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={"kb_row_" + backupName}
                        selected={isSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isSelected} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {backupName}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={1} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
        <Dialog
          open={this.state.openConfirmRestore}
          onClose={this.handleConfirmRestoreClose}
          aria-labelledby="storage-dialog-title"
          aria-describedby="storage-dialog-description"
        >
          <DialogTitle  id="storage-dialog-title">Confirm Storage Restoration</DialogTitle>
          <DialogContent>
            <DialogContentText id="storage-dialog-description">
              Current storage image will be stored in a snap instance.
              Storage replacement is a lengthy operation, please allow at least 10 seconds to complete.
              Are you sure you want to replace current storage with {this.state.selected[0]}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              color='primary'
              onClick={this.handleConfirmRestoreClose}>
              Cancel
            </Button>
            <Button
              color='primary'
              onClick={this.handleConfirmRestore}>
              Restore
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openUpload}
          onClose={this.handleUploadClose}
          aria-labelledby="upload-dialog-title"
          aria-describedby="upload-dialog-description"
        >
          <DialogTitle  id="upload-dialog-title">Storage Image Upload</DialogTitle>
          <DialogContent>
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={this.selectFile} />
                <Button
                  className="btn-choose"
                  variant="outlined"
                  component="span" >
                   Choose Files
                </Button>
              </label>
              <Box>
                {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
              </Box>
              <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
                 {message}
              </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              color='primary'
              onClick={this.handleUploadClose}>
              Cancel
            </Button>
            <Button
              color='primary'
              disabled={!selectedFiles}
              onClick={this.handleUpload}>
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

StorageTable.propTypes = {
  classes: PropTypes.object.isRequired,
  backupList: PropTypes.array.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  openFilter: PropTypes.func.isRequired,
  createBackup: PropTypes.func.isRequired,
  downloadBackup: PropTypes.func.isRequired,
  restoreBackup: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  loadStorageList: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default withStyles(StorageTable, styles);

import {Box, Switch} from "@mui/material";
import styles from "../styles";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

const DarkModeSwitch = ({isDarkMode, setIsDarkMode}) => {
    return <Box style={styles.darkModeSwitchContainer}>
        <LightModeIcon/>
        <Switch
            checked={isDarkMode}
            onChange={() => setIsDarkMode(!isDarkMode)}
        />
        <DarkModeIcon/>
    </Box>
}

export default DarkModeSwitch
const generateUUID = () => {
  let d = new Date().getTime();
  let uuid = 'xxxxxxxxxxxx5xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random()*16)%16 | 0;
      d = Math.floor(d/16);
      return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });
  return uuid;
}

const handleResponse = async (response) => {
  if (!response.ok) {
    let err = response.statusText || await response.text();
    throw Error("Server error: " + response.status + ' - ' + err);
  } else {
    let contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return response.json();
    }
    if (contentType && contentType.includes("application/tar+gzip")) {
  console.log("it is a blob")
      return response.blob();
    }
    return response.text();
  }
}

const handleError = (response) => {
  if (!response.ok) {
    let err;
    try {
      err = response.json();
    } catch (ex) {
      throw Error("Server error: " + response.status + ' ' + response.statusText);
    }
    throw err;
  }
  let contentType = response.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    return response.json();
  }
  if (contentType && contentType.includes("application/tar+gzip")) {
console.log("it is a blob")
    return response.blob();
  }
  return response.text();
}

const processErrorPromise = (promise, processor) => {
  if (promise.then) {
console.log("promise...")
    promise.then(err => {
console.log("found error...", err);
      if (err.error || err.status) {
        const msg = err.message ? ' - ' + err.message : '';
        processor(err.status + ': ' + err.error + msg);
      } else {
        processor(err);
      }
    });
  } else {
console.log(promise);
    processor(promise.toString());
  }
}

const copyToClipboard = (text) => {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
  } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.focus();
      textArea.select();

      try {
          let ok = document.execCommand('copy');
          if (!ok) {
            alert(text);
            return false;
          }
      } catch (error) {
          console.error(error);
          alert(text);
          return false;
      } finally {
          textArea.remove();
      }
  }
  return true;
}

function pad(n, width, z) { while(('' + n).length<width) n = '' + z + n; return n;}

const asTimestampLabel = (d) => {
  return asTimeLabel(new Date(+d));
}

const asTimeLabel = (d) => {
  let days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  let months = ["January","February","March","April","May","June","July", "August", "September", "October", "November", "December"];
  let current = new Date();

  if (current.getDate() === d.getDate() && current.getMonth() === d.getMonth() && current.getFullYear() === d.getFullYear()) {
    return pad(d.getHours(), 2, 0).toString() + ':' + pad(d.getMinutes(), 2, 0)
  } else if (( current.getDate() !== d.getDate() || current.getMonth() !== d.getMonth() ) && current.getFullYear() === d.getFullYear()) {
    return days[d.getDay()] + ' ' + months[d.getMonth()] + ' ' + pad(d.getDate(), 2, 0) + ' ' + pad(d.getHours(), 2, 0) + ':' + pad(d.getMinutes(), 2, 0)
  } else {
    return days[d.getDay()] + ' ' + months[d.getMonth()] + ' ' + pad(d.getDate(), 2, 0) + ', ' + d.getFullYear().toString() + ' ' + pad(d.getHours(), 2, 0) + ':' + pad(d.getMinutes(), 2, 0)
  }
}

export { generateUUID, processErrorPromise, handleError, handleResponse, copyToClipboard, asTimestampLabel,  asTimeLabel}

import React, {useState, useEffect, useRef} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import SendIcon from '@mui/icons-material/Send';

import styles from "../../styles";
import {asTimestampLabel, handleError, handleResponse} from "../../utils/tools";
import MessageAlert from "../../components/MessageAlert";
import FormLoadingBar from "../../components/FormLoadingBar";

const ChatPage = ({ token }) => {
  const [conversation, setConversation] = useState({messages:[]});
  const [prompt, setPrompt] = useState("");
  const [errorMessage, setErrorMessage] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const scrollRef = useRef(null);

  const pageStyle = {...styles.fullDimensions, ...styles.container}

  useEffect(() => {
      if (errorMessage !== '')
          setTimeout(() => setErrorMessage(''), 5000)
      if (infoMessage !== '')
          setTimeout(() => setInfoMessage(''), 3000)
  }, [errorMessage, infoMessage])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [conversation]);

  const sendPrompt = (event) => {
      event.preventDefault()

      const data = {
        prompt, conversation
      }
      setIsLoading(true)
      return fetch(`/chat/completion?token=${token}`, {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json', }),
          body: JSON.stringify(data)
      }).then(response => handleResponse(response))
      .then(cnv => {setConversation(cnv); setPrompt(""); console.log(cnv);})
      .then(_ => setIsLoading(false))
      .catch(error => {
        setIsLoading(false);
        console.error(error);
				setErrorMessage(error.message);
			});
  }

  return <main style={pageStyle}>
        <MessageAlert message={errorMessage} setMessage={setErrorMessage} />
        <MessageAlert message={infoMessage} setMessage={setInfoMessage} severity={"info"} />
        <Grid container>
           <Grid item xs={12} >
               <Typography variant="h5" className="header-message">Chat</Typography>
           </Grid>
       </Grid>
       <Grid container component={Paper} style={styles.chatSection}>
           <Grid item xs={3} style={styles.borderRight500}>
               <List>
                   <ListItem button key="RemySharp">
                       <ListItemIcon>
                       <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" />
                       </ListItemIcon>
                       <ListItemText primary="John Wick"></ListItemText>
                   </ListItem>
               </List>
               <Divider />
               <Grid item xs={12} style={{padding: '10px'}}>
                   <TextField id="outlined-basic-email" label="Search" variant="outlined" fullWidth />
               </Grid>
               <Divider />
               <List>
                   <ListItem button key="RemySharp">
                       <ListItemIcon>
                           <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" />
                       </ListItemIcon>
                       <ListItemText primary="Remy Sharp">Remy Sharp</ListItemText>
                       <ListItemText secondary="online" align="right"></ListItemText>
                   </ListItem>
                   <ListItem button key="Alice">
                       <ListItemIcon>
                           <Avatar alt="Alice" src="https://material-ui.com/static/images/avatar/3.jpg" />
                       </ListItemIcon>
                       <ListItemText primary="Alice">Alice</ListItemText>
                   </ListItem>
                   <ListItem button key="CindyBaker">
                       <ListItemIcon>
                           <Avatar alt="Cindy Baker" src="https://material-ui.com/static/images/avatar/2.jpg" />
                       </ListItemIcon>
                       <ListItemText primary="Cindy Baker">Cindy Baker</ListItemText>
                   </ListItem>
               </List>
           </Grid>
           <Grid item xs={9}>
               <List style={styles.messageArea}>
               {
                 conversation.messages.map(msg => (
                   <ListItem key={"li_" + msg.idx}>
                       <Grid container>
                           <Grid item xs={12}>
                               <ListItemText align={msg.source !== "user" ? "left" : "right"}
                                    primary={msg.content}
                                    primaryTypographyProps={{ style: { whiteSpace: "normal" } }} />
                           </Grid>
                           <Grid item xs={12}>
                               <ListItemText align={msg.source !== "user" ? "left" : "right"}
                                    secondary={asTimestampLabel(msg.created)}></ListItemText>
                           </Grid>
                       </Grid>
                   </ListItem>
                 ))
               }
                <ListItem ref={scrollRef} />
               </List>
               <Divider />
               {isLoading ? (<FormLoadingBar lastMessage={"Processing..."} />) :
               (<Grid container style={{padding: '20px'}}>
                     <Grid item xs={11}>
                         <TextField id="outlined-basic-email"
                            label="I am listening..." fullWidth
                            value={prompt}
                            onChange={(evt) => setPrompt(evt.target.value)}
                            />
                     </Grid>
                     <Grid xs={1} align="right">
                         <Fab color="primary" aria-label="send">
                            <SendIcon onClick = {sendPrompt}/>
                         </Fab>
                     </Grid>
               </Grid>)}
           </Grid>
       </Grid>
  </main>
}

export default ChatPage;

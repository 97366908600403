import {useCallback, useState} from "react";

function persistItem(key, value) {
    localStorage.setItem(key, value)
    return value
}

function usePersistState(key, initialValue) {
    const [state, setState] = useState(
        () => localStorage.getItem(key) === null ?
            persistItem(key, initialValue) :
            localStorage.getItem(key) === 'true'
    )
    const setStateAndPersist = useCallback(
        (newState) => {
            setState(newState)
            return persistItem(key, newState)
        },
        [key, setState]
    )
    return [state, setStateAndPersist]
}

export default usePersistState
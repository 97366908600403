import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';

import { handleResponse } from './../../utils/tools';

import StorageView from './StorageView';
import StorageTable from './StorageTable';
import MessageAlert from "./../../components/MessageAlert";

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(7)
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class StorageList extends Component {
	static propTypes = {
		token: PropTypes.string.isRequired,
	}

	constructor(props) {
		super(props);

		this.state = {
			selected: [],
			storageList: [],
			createBackup: false,
			checkOut: null,
			openConfirm: false,
			clearSelected: () => { },
			filter: '',
			openFilter: false,
			confirmCheckIn: false,
			checkIn: null,
      errorMessage: "",
		};

	}

	componentDidMount() {
		this.loadStorageList();
	}

  setErrorMessage = (msg) => {
    this.setState({errorMessage: msg})
    if (msg !== '') setTimeout(() => this.setState({errorMessage: ''}), 3000)
  }

	loadStorageList = () => {
		global.fetch(`/db/support/backup/list?token=${this.props.token}`, {
			credentials: 'include',
			method: 'GET',
			headers: new Headers({ 'Content-Type': 'application/json', }),
		}).then(response => handleResponse(response))
			.then(content => {
				this.setState({
					storageList: content.map(filename => filename.slice(0, -4)).filter(fileName => !this.state.filter || fileName.includes(this.state.filter))
				})
			})
			.catch(error => {
        console.error(error);
				this.setErrorMessage(error.message);
			});
	}

	deleteBackupStorage = (backupName) => {
		global.fetch(`/db/support/backup/${backupName}?token=${this.props.token}`, {
			credentials: 'include',
			method: 'DELETE',
			headers: new Headers({ 'Content-Type': 'application/json', }),
		}).then(response => handleResponse(response))
			.then(_ => {
				let idx = this.state.storageList.indexOf(backupName);
				if (idx !== -1) {
					this.state.storageList.splice(idx, 1);
				}
				this.setState({ storageList: this.state.storageList, selected: [], })
			})
			.catch(error => {
        console.error(error);
				this.setErrorMessage(error.message);
			});
	}

	downloadBackup = (name) => {
		global.fetch(`/db/support/backup/download/${name}?token=${this.props.token}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/zip',
			},
		})
			.then(response => handleResponse(response))
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					name + ".zip",
				);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
			})
			.catch(error => {
        console.error(error);
				this.setErrorMessage(error.message);
			});
	}

	restoreBackup = (name) => {
		global.fetch(`/db/restore/${name}?token=${this.props.token}`, {
			credentials: 'include',
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
      .then(response => handleResponse(response))
			.then(_ => {
				var that = this;
				setTimeout(function() {
					that.loadStorageList();
				}, 10000);
			})
			.catch(error => {
        console.error(error);
				this.setErrorMessage(error.message);
			});
	}

	upload = (file) => {
		let formData = new FormData();

		formData.append("file", file);
		return global.fetch(`/db/support/backup/upload?token=${this.props.token}`, {
			credentials: 'include',
			method: 'POST',
			body: formData,
		})
      .then(response => handleResponse(response))
      .catch(error => {
        console.error(error);
        this.setErrorMessage(error.message);
      });
	}

	isSelected = (index) => {
		return this.state.selected.indexOf(index) !== -1;
	};

	handleRowSelection = (selectedRows) => {
		this.setState({
			selected: selectedRows,
		});
	};

	handleBackupClose = () => {
		this.setState({ createBackup: false });
		this.loadStorageList();
	}

	handleCreateBackup = () => {
		this.setState({ createBackup: true });
	}

	handleConfirmClose = () => {
		this.setState({ openConfirm: false, selected: [] });
	}

	handleFilterClose = () => {
		this.setState({ openFilter: false, });
	}

	handleFilterOpen = () => {
		this.setState({ openFilter: true, });
	}

	handleFilterDo = () => {
		this.handleFilterClose();
		this.loadStorageList();
	}

	handleFilterChange = event => {
		const value = event.target.value;
		this.setState({ filter: value, })
	}

	handleDeleteSelected = (selectedList, clearSelected) => {
		this.setState({ openConfirm: true, selected: selectedList, clearSelected: clearSelected });
	}

	handleConfirmDelete = event => {
		this.handleConfirmClose();
		this.state.selected.forEach((item, i) => {
			this.deleteBackupStorage(item);
		});
		this.state.clearSelected();
		this.setState({ clearSelected: () => { } });
	}

	render() {
		if (this.state.createBackup) {
			return (
				<StorageView
					setErrorMessager={this.setErrorMessage}
					onClose={this.handleBackupClose}
					token={this.props.token}
				/>
			)
		}
    const { classes } = this.props;
		let conversationCount = this.state.selected.length;
		let storageList = this.state.storageList;
    let errorMessage = this.state.errorMessage

		return (
      <Box
  			className = {classes.root}>
				<StorageTable backupList={storageList}
					confirmDelete={this.handleDeleteSelected}
					createBackup={this.handleCreateBackup}
					openFilter={this.handleFilterOpen}
					downloadBackup={this.downloadBackup}
					restoreBackup={this.restoreBackup}
					upload={this.upload}
					loadStorageList={this.loadStorageList}
					token={this.props.token}
				/>
				<Dialog
					open={this.state.openConfirm}
					onClose={this.handleConfirmClose}
					aria-labelledby="conversation-dialog-title"
					aria-describedby="conversation-dialog-description"
				>
					<DialogTitle id="conversation-dialog-title">Confirm Backup(s) Removal</DialogTitle>
					<DialogContent>
						<DialogContentText id="conversation-dialog-description">
							Are you sure you want to remove {conversationCount} backup(s)?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							color='primary'
							onClick={this.handleConfirmClose}>
							Cancel
						</Button>
						<Button
							color='primary'
							onClick={this.handleConfirmDelete}>
							Delete Selection
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={this.state.openFilter}
					onClose={this.handleFilterClose}
					aria-labelledby="filter-dialog-title"
					aria-describedby="filter-dialog-description"
				>
					<DialogTitle id="filter-dialog-title">Filter list</DialogTitle>
					<DialogContent>
						<DialogContentText id="filter-dialog-description">
							Please provide the substring the name has to have
						</DialogContentText>
						<TextField key={'name_filter'} value={this.state.filter} multiline={false}
							fullWidth={true} label="Backup Name Filter"
							onChange={this.handleFilterChange} maxLength={128}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							color='primary'
							onClick={this.handleFilterClose}>
							Cancel
						</Button>
						<Button
							color='primary'
							onClick={this.handleFilterDo}>
							Filter
						</Button>
					</DialogActions>
				</Dialog>
        <MessageAlert message={errorMessage} setMessage={this.setErrorMessage} />
			</Box>
		)
	}
}

export default withStyles(StorageList, styles);
